// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

    /*TEST*/
    apiKey: "AIzaSyAdJN2JNo8vVYYWw-VD34BuCivG5kp5yJ4",
    authDomain: "newsapp-783d5.firebaseapp.com",
    projectId: "newsapp-783d5",
    storageBucket: "newsapp-783d5.appspot.com",
    messagingSenderId: "763792825620",
    appId: "1:763792825620:web:cdb16789878373e4fef601"


    /* PRODUCTION
    apiKey: "AIzaSyAZol-allEHFEaK4xa5fJgR9xbt1F7g_i8",
    authDomain: "pulam-news.firebaseapp.com",
    projectId: "pulam-news",
    storageBucket: "pulam-news.appspot.com",
    messagingSenderId: "1045168085758",
    appId: "1:1045168085758:web:508593b864fb25d455a3af",
    measurementId: "G-ZPTD7V0HTN"
*/


};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
