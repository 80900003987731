import { db } from "../config/firebase";
import React, { useState, useEffect } from 'react'

import { getDocs, collection, where, orderBy, limit, query, doc, getDoc } from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, Form } from "react-router-dom"

function Home() {
    const [newsLists, setNewsList] = useState([]);
    const [interNewsLists, setInterNewsList] = useState([]);
    const [cinemaLists, setCinemaList] = useState([]);
    const [sportsLists, setSportsList] = useState([]);
    const [breakingLists, setBreakingList] = useState([]);
    const [importantLists, setImportantList] = useState([]);
    const newssCollectionRef = collection(db, "News");
    const breakingCollectionRef = collection(db, "Breaking");
    const importantCollectionRef = collection(db, "Important");
    const internationalCollectionRef = collection(db, "International")
    const cinemaCollectionRef = collection(db, "Cinema");
    const sportsCollectionRef = collection(db, "Sports");

    const newsQuery = query(newssCollectionRef, orderBy("date", "desc"), limit(10))
    const internationalQuery = query(internationalCollectionRef, orderBy("date", "desc"), limit(4))
    const cinemaQuery = query(cinemaCollectionRef, orderBy("date", "desc"), limit(4))
    const sportsQuery = query(sportsCollectionRef, orderBy("date", "desc"), limit(4))
    const breakingQuery = query(breakingCollectionRef, orderBy("date", "desc"), limit(1))
    const importantQuery = query(importantCollectionRef, orderBy("date", "desc"), limit(2))

    useEffect(() => {
        const getNews = async () => {
            const data = await getDocs(newsQuery);
            setNewsList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getNews();
    },);

    useEffect(() => {
        const getInterNews = async () => {
            const data = await getDocs(internationalQuery);
            setInterNewsList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getInterNews();
    },);

    useEffect(() => {
        const getCinema = async () => {
            const data = await getDocs(cinemaQuery);
            setCinemaList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getCinema();
    },);

    useEffect(() => {
        const getSports = async () => {
            const data = await getDocs(sportsQuery);
            setSportsList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getSports();
    },);

    useEffect(() => {
        const getBreaking = async () => {
            const data = await getDocs(breakingQuery);
            setBreakingList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getBreaking();
    },);

    useEffect(() => {
        const getImportant = async () => {
            const data = await getDocs(importantQuery);
            setImportantList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getImportant();
    },);

    return (
        <div className="app-section">
            <div className="breaking-section">
                <div className="break-label">
                    BREAKING NEWS
                </div>
                <marquee className="marquee-section">
                    {breakingLists.map((news) => {
                        return (
                            <Link to={`/ViewBreakings/${news.id}`}><div><p className="Breaking-Label">****BREAKING***** {news.newsHeading}</p></div></Link>
                        );
                    })}
                </marquee>
            </div>
            <div className="breaking-news"> முக்கியச் செய்திகள்  </div>
            <div className="important-section">
                {importantLists.map((news) => {
                    return (
                        <Link to={`/ViewImportant/${news.id}`}>
                            <div className="breaking-container" style={{ margin: '10px' }}>
                                <div> <img src={news.newsImage} style={{ height: '250px', width: '400px', marginRight: '5px' }}></img> </div>
                                <p className="Important-Label">{news.newsHeading}</p>
                                <div className="Single-newsDate">
                                    <div className="date">{(news.date).toDate().toISOString().substring(0, 10)}</div>

                                    <div className="date">{Math.round(Math.abs(((new Date() - news.date)) / (24 * 60 * 60 * 1000)))}</div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div className="App-body">
                <div className="App-news">
                    <div className="breaking-news"> இலங்கை செய்திகள்</div>
                    <div className="grid">
                        {newsLists.map((news) => {
                            return (
                                <Link to={`/View/${news.id}`}>
                                    <div className="item" >
                                        <div> <img src={news.newsImage} style={{ width: '100%', backgroundSize: 'cover' }}></img> </div>
                                        <div className="Single-newsDate">
                                            <div><h1>buihnoiio</h1>udbisbxhj</div>
                                            <div className="News-Label" >{news.newsHeading}</div>
                                            <div className="date">{(news.date).toDate().toISOString().substring(0, 10)}</div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div><br></br>
                    <div className="breaking-news">  சர்வதேசச் செய்திகள் </div>
                    <div className="grid">
                        {interNewsLists.map((news) => {
                            return (
                                <Link to={`/ViewInternational/${news.id}`}>
                                    <div className="item">
                                        <div> <img src={news.newsImage} style={{ width: '100%' }}></img> </div>
                                        <div className="Single-newsDate">
                                            <div className="News-Label">{news.newsHeading}</div>
                                            <div className="date">{(news.date).toDate().toISOString().substring(0, 10)}</div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div> <br></br>
                    <div className="breaking-news"> சினிமா</div>
                    <div className="grid">
                        {cinemaLists.map((news) => {
                            return (
                                <Link to={`/View/${news.id}`}>
                                    <div className="item">
                                        <div> <img src={news.newsImage} style={{ width: '100%' }}></img> </div>
                                        <div className="Single-newsDate">
                                            <div className="News-Label">{news.newsHeading}</div>
                                            <div className="date">{(news.date).toDate().toISOString().substring(0, 10)}</div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div><br></br>
                    <div className="breaking-news"> விளையாட்டுச் செய்திகள்</div>
                    <div className="grid">
                        {sportsLists.map((news) => {
                            return (
                                <Link to={`/View/${news.id}`}>
                                    <div className="item">
                                        <div> <img src={news.newsImage} style={{ width: '100%' }}></img> </div>
                                        <div className="Single-newsDate">
                                            <div className="News-Label">{news.newsHeading}</div>
                                            <div className="date">{(news.date).toDate().toISOString().substring(0, 10)}</div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div><br></br>
                </div>
            </div>
        </div>
    );
}
export default Home;